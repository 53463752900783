<template>
  <div
    v-if="invoice"
    class="flex items-center justify-center gap-3 bg-red-600 p-1.5 text-sm text-white"
  >
    <span>{{ message }}</span>
    <a
      :href="link"
      target="_blank"
      class="whitespace-nowrap"
    >
      {{ $t('components.unpaid_invoice_bar.detail') }}
      <BaseIcon icon="fal fa-chevron-right" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/stores/auth-store'

const { $api } = useNuxtApp()

const invoice = ref<{
  createDate: string
  customer: string
  debtRecovery: boolean
  dueDate: string
  modified: string
  number: string
  orderNumbers: [{ number: string; token: string }]
  price: Money
  priceVat: Money
  shipments: string[]
  status: string
  token: string
  variableSymbol: string
} | null>(null)

// TODO: use link from API, not implemented yet
const link = computed(() => `${useRuntimeConfig().public.webUrl}/faktura/${invoice.value?.token}`)
const { t } = useI18n()

const message = computed(() => t('components.unpaid_invoice_bar.p', [invoice.value?.number ?? '']))

onMounted(async () => {
  if (!useAuthStore().isAuthenticated) {
    return
  }

  try {
    invoice.value =
      (
        await $api.post('user/invoice/list?limit=1', {
          status: 'afterDueDate',
        })
      ).data.invoices.shift() ?? null
  } catch (e: unknown) {
    console.error(e)
  }
})
</script>
