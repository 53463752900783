<template>
  <BaseAccordionClear v-if="submenu">
    <BaseAccordionClearPanel
      :show-arrow="hover"
      :class-header="accordionPanelClass"
      class-slot="mt-2"
    >
      <template #header>
        <SidebarItemLabel
          :label="label"
          :route="route"
          :icon="icon"
          :hover="hover"
          :badge-count="badgeCount"
          :badge-color="badgeColor"
          :is-sub-page="isSubPage"
          :icon-active="iconActive"
        />
      </template>

      <ul
        class="ml-9 space-y-2"
        :class="hover ? '' : 'hidden'"
      >
        <li
          v-for="item in submenu"
          :key="item.label"
        >
          <SidebarItem
            :label="item.label"
            :route="item.route"
            :hover="hover"
            :badge-count="item.badgeCount"
            :badge-color="item.badgeColor"
            :disabled="item.disabled"
            @action="$emit('action')"
          />
        </li>
      </ul>
    </BaseAccordionClearPanel>
  </BaseAccordionClear>

  <Component
    :is="wrapperType"
    v-else
    :to="route"
    :active-class="itemActiveClass"
    :class="[
      itemClass,
      isSameCategory ? itemActiveClass : undefined,
      disabled
        ? 'cursor-not-allowed text-gray-200'
        : 'cursor-pointer text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400',
    ]"
    @click="$emit('action')"
  >
    <SidebarItemLabel
      :label="label"
      :route="route"
      :icon="icon"
      :icon-active="iconActive"
      :hover="hover"
      :badge-count="badgeCount"
      :badge-color="badgeColor"
    />
  </Component>
</template>

<script lang="ts" setup>
import { clsx } from 'clsx/lite'
import { NuxtLink } from '#components'
import type { BadgeType } from '~/types/badges'

interface Props {
  route?: string
  label?: string | undefined
  icon?: string
  iconActive?: boolean
  disabled?: boolean
  hover?: boolean
  badgeCount?: number
  badgeColor?: BadgeType
  submenu?: object
  short?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  route: undefined,
  badgeCount: undefined,
  badgeColor: undefined,
  submenu: undefined,
})

defineEmits(['action'])

const currentRoute = useRoute()

const itemClass = clsx(
  'relative items-center gap-3 whitespace-nowrap rounded-lg p-2 transition dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white',
  !props.short && 'flex w-full',
)
const itemActiveClass = clsx(
  'bg-gray-100 text-gray-900 hover:bg-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800',
)

const wrapperType = computed(() => (props.route ? NuxtLink : 'span'))

const isSubPage = computed<boolean>(() => {
  return useRoute().path.startsWith(props.route as string)
})

const isSameCategory = computed<boolean>(() => {
  if (props.route === '/') {
    return currentRoute.path === props.route
  } else if (props.route) {
    return currentRoute.path.includes(props.route)
  }

  return isSubPage.value || false
})

const accordionPanelClass = computed(() => clsx(itemClass, isSameCategory.value && itemActiveClass))
</script>
