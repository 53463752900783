<template>
  <BaseIcon
    v-if="icon"
    :icon="activeIcon"
    class="shrink-0"
    size="lg"
  />
  <div
    class="flex flex-1 items-center justify-between whitespace-nowrap leading-none"
    :class="hover ? '' : 'hidden'"
  >
    {{ label }}

    <BaseBadge
      v-if="badgeCount"
      :type="badgeColor"
      size="xxs"
    >
      <template #icon>
        {{ badgeCount }}
      </template>
    </BaseBadge>
  </div>
</template>

<script lang="ts" setup>
import type { BadgeType } from '~/types/badges'

interface Props {
  route?: string
  label: string | undefined
  icon?: string
  iconActive?: boolean
  hover?: boolean
  isSubPage?: boolean
  badgeCount?: number
  badgeColor?: BadgeType
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  route: undefined,
  badgeCount: undefined,
  badgeColor: undefined,
})

const currentRoute = useRoute()

const isSameCategory = computed<boolean>(() => {
  if (props.route === '/') {
    return currentRoute.path === props.route
  } else if (props.route) {
    return currentRoute.path.includes(props.route)
  }

  return false
})

const activeIcon = computed<string>(() => {
  return (isSameCategory.value || props.isSubPage || props.iconActive ? 'fas ' : 'fal ') + props.icon
})
</script>
