<template>
  <div
    v-if="baseStore.isLoading"
    class="fixed left-1/2 top-1/2 z-[1001] -translate-x-1/2 -translate-y-1/2"
  >
    <div class="rounded-md bg-gray-400 px-2 py-3 opacity-75">
      <div class="animate-spin">
        <BaseIcon
          icon="fal fa-spinner-third"
          color="white"
          size="3x"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBaseStore } from '~/stores/base-store'

const baseStore = useBaseStore()
</script>
