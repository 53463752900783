<template>
  <aside
    id="sidebar"
    v-element-hover="onHover"
    :class="sidebarClass"
    aria-label="Sidebar"
    data-tid="sidebar"
  >
    <div class="relative flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white px-3 py-4 dark:bg-gray-800" data-tid="sidebarMenu">
      <div class="flex flex-col gap-3 divide-y overflow-hidden">
        <ul
          v-for="(item, index) in sidebarItemsTop"
          :key="index"
          class="mt-2 space-y-2 pt-2 first:mt-0 first:pt-0"
        >
          <li
            v-for="inner in item"
            :key="inner.label"
            v-bind="inner.attrs"
          >
            <SidebarItem
              :label="inner.label"
              :icon="inner.icon"
              :route="inner.route"
              :hover="isHovered || isMobile || sidebarStore.isExpanded"
              :badge-count="inner.badgeCount"
              :submenu="inner.submenu"
              @action="$emit('action')"
            />
          </li>
        </ul>
        <LazySidebarNewsBox
          :class="newsClass"
        />
      </div>
      <div class="ml-auto mt-auto hidden lg:block" data-tid="sidebarPin">
        <BaseTooltip :content="sidebarStore.isExpanded ? $t('components.sidebar.pin.close') : $t('components.sidebar.pin.open')">
          <SidebarItem icon="fa-thumbtack" :icon-active="sidebarStore.isExpanded" short :label="sidebarStore.isExpanded ? $t('components.sidebar.pin.close') : $t('components.sidebar.pin.open')" @click="sidebarStore.switchExpanded" />
        </BaseTooltip>
      </div>
    </div>
  </aside>
</template>

<script lang="ts" setup>
import { vElementHover } from '@vueuse/components'
import { clsx } from 'clsx/lite'
import { useOmnicartStore } from '@/stores/omnicart-store'

const props = defineProps<{
  active?: boolean
}>()

defineEmits(['action'])

const isHovered = ref<boolean>(false)
const sidebarStore = useSidebarStore()
const isMobile = ref<boolean>(false)

onMounted(() => {
  const setMobile = () => {
    isMobile.value = Boolean(window.innerWidth < 1024)
  }

  window.addEventListener('resize', setMobile)
  setMobile()
})

const { t } = useI18n()

function onHover(state: boolean) {
  isHovered.value = state
}

const sidebarItemsTop = computed(() => [
  [
    {
      icon: 'fa-grid-2',
      label: t('components.sidebar.items.dashboard'),
      route: '/',
      attrs: {
        'data-tid': 'sidebarItemDashboard',
      },
    },
    {
      icon: 'fa-cube',
      label: t('components.sidebar.items.shipments'),
      route: '/shipments',
      attrs: {
        'data-tid': 'sidebarItemShipments',
      },
      badgeCount: useOmnicartStore().itemsCount,
    },
    ...(useRuntimeConfig().public.territory !== 'sk'
      ? [
          {
            icon: 'fa-calendar',
            label: t('components.sidebar.items.pickups.title'),
            route: '/pickups',
            submenu: [
              {
                label: t('components.sidebar.items.pickups.plan'),
                route: '/pickups/plan',
              },
              {
                label: t('components.sidebar.items.pickups.addresses'),
                route: '/pickups/addresses',
              },
            ],
            attrs: {
              'data-guide-anchor': 'sidebarItemPickups',
              'data-tid': 'sidebarItemPickups',
            },
          },
        ]
      : []),
    {
      icon: 'fa-address-book',
      label: t('components.sidebar.items.address_book'),
      route: '/address-book',
      attrs: {
        'data-tid': 'sidebarItemAddressBook',
      },
    },
    {
      icon: 'fa-triangle-exclamation',
      label: t('components.sidebar.items.claims'),
      route: '/claims',
      attrs: {
        'data-tid': 'sidebarItemClaims',
      },
    },
    {
      icon: 'fa-circle-euro',
      label: t('components.sidebar.items.invoicing'),
      route: '/invoicing',
      attrs: {
        'data-tid': 'sidebarItemInvoicing',
      },
    },
  ],
  [
    {
      icon: 'fa-gear',
      label: t('components.sidebar.items.settings'),
      route: '/settings',
      attrs: {
        'data-guide-anchor': 'sidebarItemSettings',
        'data-tid': 'sidebarItemSettings',

      },
    },
  ],
])

const sidebarClass = computed<string>(() =>
  clsx(
    'fixed left-0 top-0 z-20 flex h-full shrink-0 flex-col overflow-hidden pt-[57px] transition-[width] md:pt-[59px]',
    isHovered.value || sidebarStore.isExpanded ? 'lg:w-60' : 'lg:w-16',
    props.active ? 'w-full' : 'w-0',
  ),
)

const newsClass = computed<string>(() =>
  clsx(
    'relative overflow-hidden duration-300',
    isHovered.value || sidebarStore.isExpanded ? 'top-0 max-h-none opacity-100 transition-[top,opacity,max-height] delay-300' : 'top-2.5 max-h-0 opacity-0 transition-none',
  ),
)
</script>
