<template>
  <div
    class="inline-flex divide-x divide-orange-300 dark:divide-orange-800 max-md:hidden"
    role="group"
    data-tid="orderSubmenu"
  >
    <BaseButton
      class="rounded-r-none"
      icon="fas fa-plus"
      size="xs"
      data-tid="orderSubmenuNewOrder"
      @click="openNewShipmentModal()"
    >
      {{ $t('components.navbar.cta') }}
    </BaseButton>
    <div data-tid="orderSubmenuDropdownButton">
      <BaseDropdown data-tid="orderSubmenuDropdownList" placement="bottom-end">
        <BaseButton
          class="rounded-l-none"
          icon="fal fa-chevron-down"
          size="xs"
        />
        <template #content="slotProps">
          <BaseDropdownItem
            v-for="item in dropdownItems"
            :key="item.label"
            data-tid="orderSubmenuDropdownItem"
            @click="slotProps.close"
            @click.prevent="item.action()"
          >
            {{ item.label }}
          </BaseDropdownItem>
        </template>
      </BaseDropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const dropdownItems = [
  {
    label: t('components.navbar.cta'),
    action: openNewShipmentModal,
  },
  {
    label: t('pages.shipments.integration_sync_btn'),
    action: openSyncIntegrationsModal,
  },
  {
    label: t('pages.shipments.upload_btn'),
    action: openUploadModal,
  },
  {
    label: t('generic.plan_pickups'),
    action: planCollectionRequest,
  },
]
</script>
