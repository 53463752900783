<template>
  <div class="flex flex-col md:h-screen">
    <Navbar
      class="flex-none"
      @toggle="sidebarActive = !sidebarActive"
    />
    <div class="flex flex-1 overflow-hidden pt-[57px] md:pt-[59px]">
      <Sidebar
        :active="sidebarActive"
        @action="sidebarActive = false"
      />
      <div class="relative size-full overflow-y-auto px-4 md:transition-[margin-left]" :class="sidebarStore.isExpanded ? 'lg:ml-60' : 'lg:ml-16'">
        <BaseLoading />
        <main class="relative flex h-full flex-col">
          <UnpaidInvoiceBar class="-mx-4" />
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const sidebarActive = ref<boolean>(false)
const sidebarStore = useSidebarStore()
</script>
