<template>
  <BaseDropdown position="bottom-end">
    <div
      v-if="userProfile && userProfile.avatar"
      class="size-8 rounded-full bg-gray-100"
      data-tid="avatarMenu"
    >
      <img
        :src="userProfile.avatar"
        class="size-8 rounded-full"
        loading="eager"
        alt=""
      >
    </div>
    <div
      v-else
      class="flex size-8 items-center justify-center rounded-full bg-[#34f7c2] text-sm font-medium text-black"
      data-tid="avatarMenu"
    >
      <BaseIcon icon="fal fa-user" />
    </div>

    <template #header>
      <div class="font-medium">
        {{ userName }}
      </div>

      <BaseBadge size="xs">
        {{ userContract }}
      </BaseBadge>
    </template>

    <template #content="slotProps">
      <template
        v-for="item in userMenuItems"
        :key="item.label"
      >
        <BaseDropdownItem
          v-if="item.action"
          :icon="item.theme ? (colorMode.value === 'dark' ? 'fal fa-moon' : 'fal fa-sun-bright') : item.icon"
          @click="slotProps.close"
          @click.prevent="item.action()"
        >
          {{ item.label }}
        </BaseDropdownItem>
        <BaseDropdownItem
          v-else
          :icon="item.icon"
          :href="item.route"
          :external="item.external"
          :status="item.status"
        >
          {{ item.label }}
        </BaseDropdownItem>
      </template>
    </template>
    <template #footer="slotProps">
      <BaseDropdownItem
        icon="fal fa-arrow-right-from-bracket"
        @click="slotProps.close"
        @click.prevent="logout"
      >
        {{ t('components.user_menu.sign_out.title') }}
      </BaseDropdownItem>
    </template>
  </BaseDropdown>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/user-store'
import { LazyLangSwitchModal, LazyNewFeatureModal, LazyThemeSwitchModal, LazyWelcomeVideoModal } from '#components'

const { t } = useI18n()
const colorMode = useColorMode()
const { contractTypeMap } = useUser()

const userProfile = computed<User | null>(() => useUserStore().profile)
const userContract = computed<string>(() => contractTypeMap[useUserStore().contractType])

const userName = computed<string>(() => {
  if (userProfile.value) {
    if (userProfile.value.firstname || userProfile.value.surname) {
      return `${userProfile.value.firstname} ${userProfile.value.surname} ${
        userProfile.value.company ? `(${userProfile.value.company})` : ''
      }`
    } else if (userProfile.value.company) {
      return userProfile.value.company
    } else {
      return userProfile.value.email
    }
  }
  return ''
})

const userMenuItems = computed(() => [
  {
    icon: 'fal fa-earth-americas',
    label: t('components.user_menu.language'),
    action: switchLanguage,
  },
  {
    theme: true,
    label: t('components.user_menu.theme'),
    action: switchTheme,
  },
  {
    icon: 'fal fa-newspaper',
    label: t('components.user_menu.news'),
    external: true,
    status: true,
    route: 'https://www.zaslat.cz/novinky',
  },
  {
    icon: 'fal fa-graduation-cap',
    label: t('components.user_menu.guide'),
    action: welcomeGuide,
  },
  {
    icon: 'fal fa-envelope',
    label: t('components.user_menu.contact'),
    external: true,
    route: 'https://www.zaslat.cz/kontakt',
  },
  {
    icon: 'fal fa-lightbulb',
    label: t('components.user_menu.new_feature'),
    action: newFeature,
  },
])

const newFeature = () => {
  setTimeout(() => {
    const modal = openModal(LazyNewFeatureModal, {
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
      },
    })
  }, 100)
}

const switchTheme = () => {
  const modal = openModal(LazyThemeSwitchModal, {
    onClose() {
      modal.close()
    },
    onDone() {
      modal.close()
    },
  })
}

const switchLanguage = () => {
  const modal = openModal(LazyLangSwitchModal, {
    onClose() {
      modal.close()
    },
    onDone() {
      modal.close()
    },
  })
}

const welcomeGuide = () => {
  const modal = openModal(
    LazyWelcomeVideoModal,
    {
      onClose() {
        modal.close()
      },
      onStartGuide() {
        modal.close()
      },
    },
    {
      size: 'lg',
    },
  )
}

const logout = async () => {
  const confirm = await openConfirm(
    t('components.user_menu.sign_out.title'),
    t('components.user_menu.sign_out.p'),
    t('components.user_menu.sign_out.cta'),
  )
  if (confirm) {
    await navigateTo('/logout')
  }
}
</script>
